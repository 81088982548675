import { useState } from "react";
import { Network } from "../enums/network.enum";
import { refreshWhitelist } from "../services/reservoir.service";

const Collections = (props: any) => {
  const [network, setNetwork] = useState<Network>(1);
  const [address, setAddress] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");

  const handleNetworkSelection = (e: any) => {
    const value = e.target.value;
    setNetwork(value);
  };

  const handleAddress = (e: any) => {
    const value = e.target.value;
    setAddress(value);
  };

  const onSubmit = async () => {
    const res = await refreshWhitelist(
      address.trim().toLowerCase(),
      network,
      props.apiKey
    );
    setAnswer(res);
  };

  return (
    <>
      <h2>Collection</h2>
      <div className="form-control">
        <select onChange={handleNetworkSelection}>
          <option value={Network.Ethereum}>Ethereum</option>
          <option value={Network.Polygon}>Polygon</option>
        </select>
      </div>
      <div className="form-control">
        <label>Collection address</label>
        <input onChange={handleAddress} />
      </div>
      {answer}
      <button onClick={onSubmit}>Submit</button>
    </>
  );
};
export default Collections;
