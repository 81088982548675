import { useState } from "react";
import { setWhitelistByEmail } from "../services/user.service";

function stringToArray(input: string): string[] {
  return input
    .split("\n")
    .map((a: string) => a.trim())
    .map((a: string) => a.toLowerCase());
}

const WhitelistByEmail = (props: any) => {
  const [emails, setEmails] = useState<string>("");
  const [answers, setAnswers] = useState<string[]>([]);

  const handleAddresses = (e: any) => {
    setEmails(e.target.value);
  };
  const onSubmit = async () => {
    const array = stringToArray(emails);

    const results = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];

      try {
        const res = await setWhitelistByEmail(element, props.apiKey);
        results.push(res.message);
      } catch (error: any) {
        results.push(error);
      }
    }
    setAnswers([...results]);
  };

  return (
    <>
      <h2>Whitelist by email</h2>
      <div className="form-control">
        <label>Emails</label>
        <textarea rows={20} onChange={handleAddresses} />
      </div>
      <button onClick={onSubmit}>Submit</button>
      <div>
        {answers.map((a: any) => {
          return <div key={a}>{a}</div>;
        })}
      </div>
    </>
  );
};
export default WhitelistByEmail;
