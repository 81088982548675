import { BASE_URL } from "../constants";

export const setWhitelist = async (address: string, key: string) => {
  const res = await fetch(`${BASE_URL}/user/whitelist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ address: address, key: key }),
  });
  const response = await res.json();
  return response;
};
export const setWhitelistByEmail = async (email: string, key: string) => {
  const res = await fetch(`${BASE_URL}/user/whitelist-by-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email, key: key }),
  });
  const response = await res.json();
  return response;
};
export const setUserRight = async (userInfo: string, key: string) => {
  const res = await fetch(`${BASE_URL}/user/set-right`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ info: userInfo, key: key }),
  });
  const response = await res.json();
  return response;
};
