import React, { useCallback, useState } from "react";
import { getPageOwner } from "../services/page.service";

export default function PageOwner() {
  const [url, setUrl] = useState<string>("");
  const [owner, setOwner] = useState<any>(null);

  const handleOnChange = useCallback((event: any) => {
    const url = event.target.value;
    setUrl(url);
  }, []);

  const handleOnClick = useCallback(async () => {
    const slug = url.trim().split("/");
    const owner = await getPageOwner(slug[slug.length - 1]);
    setOwner(owner);
  }, [url]);

  return (
    <>
      <h2>Page ownership</h2>
      <div>
        <label>Enter the page url</label> <br />
        <input onChange={handleOnChange} type="text" />
        <button onClick={handleOnClick}>Submit</button>
        {owner && (
          <>
            <div>Owner of the page:</div>
            <div>address: {owner.accounts}</div>
            {owner.authType === 'email' && <div>email: {owner.email}</div>}
          </>
        )}
      </div>
    </>
  );
}
