import { Page } from "../components/Pages";
import { BASE_URL } from "../constants";

export const searchPages = async (slug: string) => {
  const res = await fetch(`${BASE_URL}/page/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ search: slug }),
  });
  const response = await res.json();
  return response.pages;
};

export const editPage = async (page: Page, key: string) => {
  const res = await fetch(`${BASE_URL}/page/update`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ page: page, key }),
  });
  const response = await res.json();
  return response.message;
};
export const getPageOwner = async (slug: string) => {
  const res = await fetch(`${BASE_URL}/page/get-page-owner/${slug}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const response = await res.json();
  return response;
};
export const transferPage = async (
  userInfo: string,
  slug: string,
  key: string
) => {
  const res = await fetch(`${BASE_URL}/page/transfer`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userInfo, slug, key }),
  });
  const response = await res.json();
  return response;
};
export const addCoOwner = async (
  id: string,
  address: string,
  key: string
) => {
  const res = await fetch(`${BASE_URL}/page/add-co-owner/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ address, key }),
  });
  const response = await res.json();
  return response;
};
