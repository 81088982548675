import { BASE_URL } from "../constants";

export const refreshAirtableDatas = async (key: string) => {
  const res = await fetch(`${BASE_URL}/airtable/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ key }),
  });
  return res.json();
};
