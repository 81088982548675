import React, { useState } from "react";
import { editPage, searchPages, transferPage } from "../services/page.service";
import update from "immutability-helper";
import { AddCoOwner } from "./AddCoOwner";
export interface Page {
  title: string;
  slug: string;
  url: string;
  id: string;
}

export default function Pages({ apiKey }: { apiKey: string }) {
  const [slug, setSlug] = useState<string>("");
  const [pages, setPages] = useState<Page[]>([]);
  const [error, seterror] = useState<string>("");
  const [selectedPage, setselectedPage] = useState<Page | null>(null);
  const [pageForCoOwner, setPageForCoOwner] = useState<Page | null>(null);
  const [userInfo, setUserInfo] = useState<string>("");
  const [transferResponse, setTransferResponse] = useState<string>("");

  const handleSearch = (e: any) => {
    setSlug(e.target.value);
  };

  const onSubmit = async () => {
    seterror("");
    const pages = await searchPages(slug);
    setPages(pages);
  };

  const handleEditRow = async (index: number) => {
    const pageToEdit = pages[index];
    const res = await editPage(pageToEdit, apiKey);
    seterror(res);
  };

  const handleOnChangeTitle = (value: string, index: number) => {
    const tmp = pages;
    var updatedPage = update(tmp[index], { title: { $set: value } });
    var newPages = update(tmp, {
      $splice: [[index, 1, updatedPage]],
    });
    setPages([...newPages]);
  };

  const handleOnChangeSlug = (value: string, index: number) => {
    const tmp = pages;
    var updatedPage = update(tmp[index], {
      slug: { $set: value },
      url: { $set: "/" + value },
    });
    var newPages = update(tmp, {
      $splice: [[index, 1, updatedPage]],
    });
    setPages([...newPages]);
  };

  const handleUserInfo = (e: any) => {
    const userInfo: string = e.target.value.toLowerCase();
    setUserInfo(userInfo);
  };

  const onClickTransfer = async () => {
    if (selectedPage) {
      const res = await transferPage(userInfo, selectedPage.slug, apiKey);
      setTransferResponse(res.message);
    }
  };

  return (
    <>
      <h2>Pages</h2>
      <div className="form-control">
        <label>Search pages</label>
        <div className="search-input">
          <input onChange={handleSearch} />
          <button onClick={onSubmit}>Search</button>
        </div>
      </div>
      <div>{error}</div>
      <table className="page-table">
        <thead>
          <tr>
            <th>title</th>
            <th>slug</th>
            <th>url</th>
          </tr>
        </thead>
        <tbody>
          {pages.map((p: Page, index: number) => {
            return (
              <tr key={p.id}>
                <td>
                  <input
                    onChange={(e) => handleOnChangeTitle(e.target.value, index)}
                    defaultValue={p.title}
                  />
                </td>
                <td>
                  <input
                    onChange={(e) => handleOnChangeSlug(e.target.value, index)}
                    defaultValue={p.slug}
                  />
                </td>
                <td>
                  <input value={p.url} defaultValue={p.title} disabled={true} />
                </td>
                <td>
                  <button onClick={() => handleEditRow(index)}>edit</button>
                  <button onClick={() => setselectedPage(p)}>transfert</button>
                  <button onClick={() => setPageForCoOwner(p)}>Add Co-owner</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {selectedPage ? (
        <>
          <h2>Transfer {selectedPage.slug} to:</h2>
          <div>
            <input
              type="text"
              placeholder="Paste wallet address or email here"
              onChange={handleUserInfo}
            />
            <button onClick={onClickTransfer}>Transfer</button>
          </div>

          {transferResponse ? <div>{transferResponse}</div> : null}
        </>
      ) : null}
      {pageForCoOwner ? (
        <AddCoOwner apiKey={apiKey} page={pageForCoOwner} />
      ) : null}
    </>
  );
}
