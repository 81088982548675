import { BASE_URL } from "../constants";
import { Network } from "../enums/network.enum";

export const refreshWhitelist = async (
  address: string,
  network: Network,
  key: string
) => {
  const res = await fetch(`${BASE_URL}/collection/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ address: address, network: network, key: key }),
  });
  const response = await res.json();
  return response.message;
};
