import { useState } from "react";
import "./App.css";
import Whitelist from "./components/Whitelist";
import Collections from "./components/Collections";
import Pages from "./components/Pages";
import WhitelistByEmail from "./components/WhitelistByEmail";
import PageOwner from "./components/PageOwner";
import NavMenu from "./components/NavMenu";
import Airtable from "./components/Airtable";
import UserRight from "./components/UserRight";

function App() {
  const [apiKey, setApiKey] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleOnChangeKey = (e: any) => {
    setApiKey(e.target.value);
  };

  const handleOnClickTab = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <div className="main">
      <NavMenu handleOnClickTab={handleOnClickTab} selectedTab={selectedTab} />
      <div className="container">
        <div className="form-control">
          <label>Key</label>
          <input onChange={handleOnChangeKey} />
        </div>
        {selectedTab === 0 ? (
          <>
            <Whitelist apiKey={apiKey} />
            <WhitelistByEmail apiKey={apiKey} />
            <UserRight apiKey={apiKey} />
          </>
        ) : null}
        {selectedTab === 1 ? <Collections apiKey={apiKey} /> : null}
        {selectedTab === 2 ? (
          <>
            <Pages apiKey={apiKey} />
            <PageOwner />
          </>
        ) : null}
        {selectedTab === 3 ? (
          <>
            <Airtable apiKey={apiKey} />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default App;
