import React, { useState } from 'react'
import { addCoOwner } from '../services/page.service';
import { Page } from './Pages';

interface IAddCoOwnerProps {
    page: Page;
    apiKey: string;
}

export const AddCoOwner = ({ page, apiKey }: IAddCoOwnerProps) => {

    const [wallet, setWallet] = useState<string>("");
    const [response, setResponse] = useState<string>("")

    const handleWalletAddress = (event: any) => {
        const value = event.target.value;
        setWallet(value);
    }

    const onSubmit = async () => {
        const res = await addCoOwner(page.id, wallet, apiKey);
        setResponse(res.message);
    }


    return (
        <>
            <h2>Add Co-owner to page {page.slug}</h2>
            <div>
                <input
                    type="text"
                    placeholder="Paste wallet address here"
                    onChange={handleWalletAddress}
                />
                <button onClick={onSubmit}>Add</button>
            </div>

            {response ? <div>{response}</div> : null}
        </>
    )
}
