// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  display: flex;
  width: 100%;
  max-height: 100vh;
}

.form-control {
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-direction: column;
  width: 600px;
}

.container {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  padding: 25px;
  overflow: auto;
}

.search-input {
  display: flex;
}
.search-input > input {
  width: 100%;
}

.page-table {
  width: 800px;
}

.page-table td {
  text-align: center;
}

.nav-menu {
  border-right: 1px solid black;
  padding-top: 25px;
  height: calc(100vh - 25px);
  width: 250px;
}

.nav-item {
  padding: 4px 15px;
}

.nav-item:hover {
  background-color: lightgray;
}

.selected {
  background-color: gray;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,QAAQ;EACR,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd","sourcesContent":[".main {\n  display: flex;\n  width: 100%;\n  max-height: 100vh;\n}\n\n.form-control {\n  display: flex;\n  justify-content: center;\n  gap: 5px;\n  flex-direction: column;\n  width: 600px;\n}\n\n.container {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  flex-direction: column;\n  width: 100%;\n  padding: 25px;\n  overflow: auto;\n}\n\n.search-input {\n  display: flex;\n}\n.search-input > input {\n  width: 100%;\n}\n\n.page-table {\n  width: 800px;\n}\n\n.page-table td {\n  text-align: center;\n}\n\n.nav-menu {\n  border-right: 1px solid black;\n  padding-top: 25px;\n  height: calc(100vh - 25px);\n  width: 250px;\n}\n\n.nav-item {\n  padding: 4px 15px;\n}\n\n.nav-item:hover {\n  background-color: lightgray;\n}\n\n.selected {\n  background-color: gray;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
