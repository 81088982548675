import React, { useState } from "react";
import { setUserRight } from "../services/user.service";

interface IUserRight {
  apiKey: string;
}

export default function UserRight({ apiKey }: IUserRight) {
  const [userInfo, setUserInfo] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");

  const handleOnChange = (e: any) => {
    setUserInfo(e.target.value);
  };

  const onSubmit = async () => {
    const res = await setUserRight(userInfo, apiKey);
    setAnswer(res.message);
  };

  return (
    <>
      <h2>User Right</h2>
      <h5>You can give permission to user to create more than 5 pages.</h5>
      <div className="form-control">
        <label>User wallet or email</label>
        <input type="text" onChange={handleOnChange} />
      </div>
      {answer ? <div>{answer}</div> : null}

      <button onClick={onSubmit}>Submit</button>
    </>
  );
}
