import React, { useCallback, useState } from "react";
import { refreshAirtableDatas } from "../services/airtable.service";

interface IAirtable {
  apiKey: string;
}

export default function Airtable({ apiKey }: IAirtable) {
  const [message, setMessage] = useState<string>("");
  const [disable, setDisable] = useState<boolean>(false);

  const handleRefresh = useCallback(async () => {
    setDisable(true);
    setMessage("");
    const res = await refreshAirtableDatas(apiKey);
    setMessage(res.message);
    setDisable(false);
  }, [apiKey, setMessage]);

  return (
    <>
      <h2>Airtable</h2>
      {disable ? (
        <div>
          Refresh in progress, take a cofee break. It can take a couple of
          minutes.
        </div>
      ) : null}
      {message ? <div>{message}</div> : null}
      <button onClick={handleRefresh} disabled={disable}>
        Refresh
      </button>
    </>
  );
}
